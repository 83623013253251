.TextBox {
  height: 40px;
  left: 0px;
  right: 0px;
  top: 20px;
  width: 256px;
  height: 40px;
  background: #ffffff;
  padding: 0px;
}
