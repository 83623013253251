.hoveredDiv {
  position: relative;
  display: inline-block;
  pointer-events: auto;
}
.parentDiv:hover {
  background: #fafafa;
}

.parentDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 32px;
}

.hoveredDiv:hover .delete {
  display: flex;
}

.delete {
  padding-top: 7px;
  padding-right: 7px;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

.editRoleParentDiv:hover {
  background: #fafafa;
  padding-left: 32px;
  margin-left: -32px;
  padding-top: 8px;
  padding-right: 17px;
}

.editRoleParentDiv {
  padding-left: 32px;
  margin-left: -32px;
  padding-top: 8px;
  padding-right: 17px;
  position: relative;
  display: inline-block;
  pointer-events: auto;
}
.editRoleParentDiv:hover .delete {
  display: flex;
}
