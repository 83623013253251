.LoginRoot {
  position: relative;
  height: 100%;
}
.InputContainer {
  position: absolute;
  padding: 2rem 2rem 2.75rem 2rem;
  width: 410px;
  background: white;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}
.LoginButton {
  background: #0451c4;
  color: white;
  width: 21.625rem;
  height: 2.5rem;
  margin: auto;
}
