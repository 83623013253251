.addSectionBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.closeSectionBtn {
  color: #0451c4;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  border: none;
  overflow: hidden;
  cursor: pointer;
  float: right;
  margin-top: 20px;
}

hr.solid {
  border-top: 1px solid #e5e6e8;
  width: 507px;
}

.firstSection {
  width: 95%;
  /* height: 393px; */
  margin-left: 29px;
  margin-top: 32px;
  margin-bottom: 40px;
  background: #ffffff;
  border: 1px solid #b8babe;
  border-radius: 4px;
}
.addLabel {
  left: calc(50% - 97px / 2 + 0.5px);
  top: 0%;
  bottom: 0%;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #b8babe;
  display: flex;
  justify-content: center;
}

.addFieldsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 484px;
  gap: 10px;
  width: 100%;
  height: 88px;
  left: 1px;
  top: 281px;
  background: #fafafa;
}

.addFieldsBtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 126px;
  height: 40px;
  border: 2px solid #0451c4;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.addNextFieldsBtn {
  width: 155px;
  height: 40px;
  left: 16px;
  top: 704px;
  border: 2px solid #0451c4;
  cursor: pointer;
}

.addNextFieldsBtnText {
  width: 78px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #0451c4;
}

.addFieldsBtnText {
  width: 78px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #0451c4;
}

.closeTemplateBtn {
  color: #0451c4;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  border: none;
  overflow: hidden;
  cursor: pointer;
}

.btnList button {
  margin-right: 10px;
}

.btnList button:last-child {
  margin-right: 0px;
}

.deleteFieldDiv {
  display: flex;
  justify-content: end;
}

.deleteSubButton {
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  visibility: hidden;
}

.deleteSubButton img {
  margin-top: -23px;
}

.subContainer {
  background: #fafafa;
  margin-bottom: 10px;
}
.subContainerText {
  margin-bottom: 10px;
  cursor: pointer;
}

.subContainer:hover {
  cursor: pointer;
}

.subContainer:hover .deleteSubButton {
  visibility: visible;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  text-align: right;
}

.subSectionTitle {
  height: 40px;
  width: 100%;
  left: 0px;
  right: 0px;
  margin-bottom: 16px;
  background: #ffffff;
}
