.clearSearchIcon:hover {
  cursor: pointer;
}
.clearSearchIcon:active {
  border-radius: 50%;
  background-color: #d3d3d3;
}
.hideClearSearchIcon {
  visibility: hidden;
}
.clearSearchIcon {
  visibility: visible;
}
