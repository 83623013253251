.delSectionBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  border: none;
  overflow: hidden;
  cursor: pointer;
  /* float: right; */
  /* margin-top: 10px;
  margin-bottom: 10px;   */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #ce1515;
}
.closeSectionBtn {
  color: #0451c4;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  border: none;
  overflow: hidden;
  cursor: pointer;
  /* float: right; */
  margin-top: 10px;
  margin-bottom: 10px;
}
.firstSection {
  width: 95% !important;
  /* height: 393px; */
  margin-left: 29px;
  margin-top: 32px;
  margin-bottom: 40px;
  background: #ffffff;
  border: 1px solid #b8babe;
  border-radius: 4px;
}

.firstSectionEdit {
  width: 100% !important;
  /* height: 393px; */
  margin-top: 32px;
  margin-bottom: 40px;
  background: #ffffff;
  border: 1px solid #b8babe;
  border-radius: 4px;
}
.accordionTitle {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 37px;
  padding: 6px;
  color: #000a18;
  display: inline;
}
.accBtnList {
  float: right;
}
