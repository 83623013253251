
.attributesWrapper {
    margin: 20px;
}
.attributesPrimaryWrapper {
    border:1px solid #B8BABE;
    border-radius: 4px;
    padding: 12px;
}
.attributeSecondaryWrapper {
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.attributesCollapsedWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;
    border: 1px solid #B8BABE;
    border-radius: 4px;
}
.subLevelCollapsedWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding: 7px 10px;
    border: 1px solid #B8BABE;
    border-radius: 4px;
}
.subLevelExpandedWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 7px 10px;
    border: 1px solid #B8BABE;
    border-radius: 4px;
}
.subLevelExpandedOne {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.collapsedText {
    color: #000a18;
    font-size: 14px;
    font-weight: 700;
}
.collapsedRightText {
    color:#0451c4;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}
.createOrgBody {
    flex:1;
    border-bottom: 1px solid #e5e4e2;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;
}
.createOrgBody::-webkit-scrollbar { 
    display: none;
}
.showFile {
    background-color: #f5f5f6;
    height:40px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    padding: 0px 8px 0px 5px;
}
.downloadCsvTemplate {
    color: #0451C4;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    font-family: "mulish";
    cursor: pointer;
}
.downloadCsvTemplate:hover {
    text-decoration: underline;
}
.forImport {
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    padding-left: 7px;
    font-family: "mulish";
}
.selectFileWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #d3d3d3;
    border-radius: 4px;
    height: 70px;
    cursor: pointer;
}
.selectFilePartOne {
    color: #0451C4;
    font-size: 14px;
    font-family: "mulish";
    font-weight: 700;
}
.selectFilePartOne:hover {
    text-decoration: underline;
}
.selectFilePartTwo {
    color: #525862;
    font-size: 11px;
    font-family: "mulish";
}