.previewDeleteDiv {
  display: none;
}

.rowDiv:hover {
  background-color: #fafafa;
}
.rowDiv:hover .previewDeleteDiv {
  display: block;
}

.previewButton {
  background: white;
  color: #0451c4;
  border: none;
  cursor: pointer;
  font-weight: 700;
  size: 16px;
  line-height: 24px;
  font-family: 'Mulish';
  font-style: normal;
}

.deleteButton {
  background: white;
  color: #ce1515;
  border: none;
  cursor: pointer;
  font-weight: 700;
  size: 16px;
  line-height: 24px;
  font-family: 'Mulish';
  font-style: normal;
}

.imageVideoContainer {
  display:flex;
  justify-content:left;
  align-items:center;
  margin:32px 16px 16px 32px;
  overflow:hidden;
  position:relative;
}
.imageVideoWrapper {
  display:flex;
  justify-content:left;
  align-items:center;
  overflow-x:auto;
}
.imageVideoSliderLeft {
  height:24px;
  width:24px;
  border-radius:2px;
  background-color:#ffffff;
  position:absolute;
  text-align:center;
  padding-top:9px;
  z-index: 1;
}
.imageVideoSliderLeft:hover {
  background-color:aliceblue;
}

.imageVideoSliderRight {
  height:24px;
  width:24px;
  border-radius:2px;
  background-color:#ffffff;
  position:absolute;
  text-align:center;
  padding-top:9px;
  right:0px;
}
.imageVideoSliderRight:hover {
  background-color:aliceblue;
}

.imageVideoWrapper::-webkit-scrollbar { 
  display: none;
}
.textAreaField {
  width: 94%;
  padding:5px;
  margin-left:16px;
  background-color:inherit;
  border:1px solid #B8BABE;
  border-radius:5px;
  font-size:16px;
  font-family:"Mulish";
  font-weight: normal;
  color:#000A18;
  font-weight: 300px;
}
.textAreaField:hover {
  border:1px solid black;
}
.textAreaField:focus { 
  outline: none !important;
  border: 2px solid #0451C4;
  padding:4px;
}