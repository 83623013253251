.editButton {
  color: #0451c4;
  background-color: white;
  border: none;
  font-weight: 700;
  font-family: Mulish;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  padding: 16px 8px;
}

.removeButton {
  color: #ce1515;
  background-color: white;
  border: none;
  font-weight: 700;
  font-family: Mulish;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  padding: 16px 8px;
}
