.inputTextField {
  height: 40px;
  border: 1px solid #b8babe;
  border-radius: 4px;
  background-color: white;
}

.modal {
  min-width: 55.02%;
}

.textAreaField {
  width: 95%;
  padding:5px;
  background-color:inherit;
  border:1px solid #B8BABE;
  border-radius:5px;
  font-size:16px;
  font-family:"Mulish";
  font-weight: normal;
  color:#000A18;
  font-weight: 300px;
}
.textAreaField:hover {
  border:1px solid black;
}
.textAreaField:focus { 
  outline: none !important;
  border: 2px solid #0451C4;
  padding:4px;
}

@media screen and (min-width: 768px) {
  .modal {
    min-width: 100%;
  }
}
@media screen and (min-width: 900px) {
  .modal {
    min-width: 90%;
  }
}

@media screen and (min-width: 1200px) {
  .modal {
    min-width: 75%;
  }
}
@media screen and (min-width: 1400px) {
  .modal {
    min-width: 60%;
  }
}
@media screen and (min-width: 1600px) {
  .modal {
    min-width: 52%;
  }
}
