.addSectionBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.closeSectionBtn {
  color: #0451c4;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  border: none;
  overflow: hidden;
  cursor: pointer;
  float: right;
  margin-top: 20px;
}

hr.solid {
  border-top: 1px solid #e5e6e8;
  width: 507px;
}

.firstSection {
  width: 95%;
  /* height: 393px; */
  margin-left: 29px;
  margin-top: 32px;
  margin-bottom: 40px;
  background: #ffffff;
  border: 1px solid #b8babe;
  border-radius: 4px;
}
.addLabel {
  left: calc(50% - 97px / 2 + 0.5px);
  top: 0%;
  bottom: 0%;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #b8babe;
}

.addFieldsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 484px;
  gap: 10px;
  width: 100%;
  height: 88px;
  left: 1px;
  top: 281px;
  background: #fafafa;
}

.addFieldsBtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 126px;
  height: 40px;
  border: 2px solid #0451c4;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.addFieldsBtnText {
  width: 78px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #0451c4;
}

.closeTemplateBtn {
  color: #0451c4;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  border: none;
  overflow: hidden;
  cursor: pointer;
}

.btnList button {
  margin-right: 10px;
}

.btnList button:last-child {
  margin-right: 0px;
}

.deleteFieldButton {
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  display: none;
}

.deleteFieldButton img {
  margin-right: -979px;
  margin-top: -49px;
}

.fieldContainer:hover {
  cursor: pointer;
}

.fieldContainer:hover .deleteFieldButton {
  display: block;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
}

.cbDiv,
.radioDiv {
  display: flex !important;
}

.previewProjectWrapper {
  height:85%;
  overflow-y:auto;
}
.previewProjectWrapper::-webkit-scrollbar { 
  display: none; 
}
.projectMenuWrapper {
  position:relative;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #0451C4;
  border-radius: 4px;
  padding:5px 8px;
  color:#ffffff;
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 700px;
}
.previewProjectMenu {
  width:180px;
  position:absolute;
  right:47px;
  top:135px;
  margin-top:10px;
  display:flex;
  flex-direction:column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.previewProjectMenuItems {
  padding:8px;
  cursor:pointer;
  font-size: 16px;
  font-family: 'Mulish';
  font-weight: 400px;
}
.previewProjectMenuItems:hover {
  background-color: #F0F5FB;
}