.chooseOptionsContainer {
    display:flex;
    flex:1;
}
.chooseOptionsSections {
    margin-top:14px;
    width: 160px;
    height: 24px;
    display: flex;
    justify-content: center;
    font-family: Mulish;
    line-height: 24px;
    font-weight: 700;
    font-size: 16px;
    color: #000A18;
}


/* Media Queries */
.chooseOptionsContainer::-webkit-scrollbar {
    display: none;
}

@media (width <= 1518px) {
    .chooseOptionsContainer {
        overflow-y: auto;
    }
  }